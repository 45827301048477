/*================================================
Blog Details CSS
=================================================*/
@import "_variables";

.blog-details {
  .post-image {
    margin-bottom: 35px;
  }
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    cursor: pointer;
  }
  h3 {
    font-size: 25px;
    margin: {
      top: 5px;
      bottom: 15px;
    }
  }
  .blog-meta {
    ul {
      padding: 0;
      margin: 15px 0;
      list-style-type: none;

      li {
        display: inline-block;
        position: relative;
        color: $paragraph-color;
        font: {
          size: 14px;
        }
        margin: {
          left: 9px;
          right: 9px;
        }
        i {
          margin-right: 4px;
          color: $main-color;
        }
        &::before {
          width: 2px;
          height: 14px;
          background: $paragraph-color;
          left: -10px;
          top: 50%;
          position: absolute;
          content: "";
          margin-top: -6px;
          transform: rotate(11deg);
        }
        a {
          display: inline-block;
          color: $paragraph-color;

          &:hover,
          &:focus {
            color: $sub-main-color;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &:first-child {
          margin-left: 0;

          &::before {
            display: none;
          }
        }
      }
    }
  }
  p {
    text-align: justify;
    margin: {
      top: 15px;
      bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  blockquote,
  .blockquote {
    background: #f6f6f6 none repeat scroll 0 0;
    padding: 35px 20px 35px 100px;
    position: relative;
    margin: {
      top: 25px;
      bottom: 25px;
    }
    &::before {
      font-family: IcoFont !important;
      position: absolute;
      content: "\efcd";
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 55px;
      color: $main-color;
    }
    p {
      color: $black-color;
      font-style: italic;
      font: {
        size: 18px;
        weight: 600;
      }
      margin: 0;
    }
  }
}

.service-lists {
  color: $paragraph-color;
}
.post-tag-media {
  background-color: #f5f7fb;
  padding: 20px;
  margin: {
    top: 25px;
    bottom: 35px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: inline-block;

      span {
        font: {
          weight: 600;
        }
        margin-right: 5px;
        display: inline-block;
      }
      a {
        color: $paragraph-color;
        margin-right: 4px;

        &:hover,
        &:focus {
          color: $main-color;
        }
      }
    }
    &.social-share {
      text-align: right;
      li {
        padding-left: 5px;
      }
    }
  }
}

.form-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  column-gap: 1em;
}
.comments-area {
  .comments-title,
  .comment-reply-title {
    font-size: 22px;
    margin-bottom: 25px;
  }
  .comment-reply-title {
    margin-bottom: 8px;
  }
  ol,
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      ol,
      ul {
        li {
          margin: {
            top: 15px;
            left: 35px;
            bottom: 15px;
          }
        }
      }
      .comment-body {
        background: #f7f7ff;
        padding: 25px 20px;
        position: relative;

        .comment-meta {
          position: relative;
          padding: {
            left: 85px;
            top: 8px;
          }
          .comment-author {
            img {
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 50%;
              max-width: 68px;
            }
            .fn {
              font: {
                size: 16px;
                weight: 600;
              }
              margin-bottom: 5px;
              display: block;
            }
            .says {
              display: none;
            }
          }
          .comment-metadata {
            a {
              display: inline-block;
              color: $paragraph-color;
              font-size: 14px;

              &:hover,
              &:focus {
                color: $main-color;
              }
            }
          }
        }
        .comment-content {
          margin: {
            top: 25px;
          }
        }
        .reply {
          position: absolute;
          right: 30px;
          top: 30px;

          a {
            display: inline-block;
            background: $black-color;
            padding: 5px 15px;
            color: $white-color;

            &:hover,
            &:focus {
              background: $main-color;
            }
          }
        }
      }
    }
  }
  .comment-respond {
    margin-top: 30px;

    .comment-notes {
      font-size: 14px;
      margin-bottom: 20px;
    }
    .comment-form {
      p {
        margin-bottom: 25px;
      }
    }
    .comment-form-comment,
    .comment-form-author,
    .comment-form-email,
    .comment-form-country,
    .comment-form-contact,
    .comment-form-academic,
    .comment-form-course,
    .comment-form-interest,
    .comment-form-looking,
    .comment-form-dob {
      input,
      input[type="date"],
      select,
      textarea {
        display: block;
        width: 100%;
        height: 50px;
        background-color: #f5f5f5;
        outline: 0 !important;
        box-shadow: unset !important;
        border: 1px solid #eeeeee;
        padding: 10px 20px;
        border-radius: 6px;
        transition: $transition;

        &:focus {
          border-color: $main-color;
        }
      }
      textarea {
        height: auto;
      }
    }
    .form-submit {
      margin: 0 !important;
      input {
        display: inline-block;
        background: $main-color;
        border: none;
        color: $white-color;
        padding: 13px 25px;
        text-transform: uppercase;
        font-weight: 600;
        outline: 0 !important;
        margin-top: 5px;
        transition: $transition;

        &:hover,
        &:focus {
          background: $black-color;
          box-shadow: 0 13px 27px 0 rgba(255, 82, 2, 0.11);
        }
      }
    }
  }
}

/*================================================
  Sidebar CSS
  =================================================*/
.sidebar {
  .widget {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
    .widget-title {
      font-size: 22px;
      margin-bottom: 25px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 2px;
        width: 17px;
        height: 17px;
        background: $main-color;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        transition: $transition;
      }
    }
    &:hover,
    &:focus {
      .widget-title {
        &::before {
          transform: rotate(180deg);
        }
      }
    }
    &.widget_search {
      background: $white-color;
      box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
      padding: 15px;

      form {
        position: relative;

        .form-control {
          background: transparent;
        }
        button {
          position: absolute;
          right: 5px;
          top: 0;
          height: 100%;
          border: none;
          outline: 0;
          box-shadow: unset;
          background: transparent;
          color: $main-color;
          transition: $transition;

          &:hover,
          &:focus {
            color: $sub-main-color;
          }
        }
      }
    }
    &.widget_categories {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          position: relative;
          border-top: 1px solid #eeeeee;
          padding: {
            top: 10px;
            bottom: 10px;
            left: 18px;
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 10px;
            height: 10px;
            background: $main-color;
            margin-top: -5px;
            transition: $transition;
          }
          &:last-child {
            border-bottom: 1px solid #eeeeee;
          }
          a {
            color: $paragraph-color;

            &:hover {
              color: $main-color;
            }
          }
          &:hover {
            &::before {
              border-radius: 50%;
            }
          }
        }
      }
    }
    &.widget_recent_entries {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          position: relative;
          padding-left: 100px;
          margin-bottom: 15px;
          padding-right: 20px;

          a {
            display: block;

            img {
              position: absolute;
              left: 0;
              top: 3px;
              width: 85px;
              height: 65px;
            }
          }
          h5 {
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 2px;
            font-weight: 600;

            a {
              color: $black-color;
              display: inline-block;

              &:hover,
              &:focus {
                color: $main-color;
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &.widget_tag_cloud {
      .tagcloud {
        a {
          font-size: 14px !important;
          border: 1px dashed #eeeeee;
          padding: 6px 20px;
          margin-top: 5px;
          color: #707070;
          display: inline-block;
          background: #f5f5f5;
          margin-right: 7px;
          border-radius: 30px;
          margin-bottom: 5px;

          &:hover {
            background: $main-color;
            color: $white-color;
            border-color: $main-color;
          }
        }
      }
    }
    &.widget_archive {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          position: relative;
          border-top: 1px solid #eeeeee;
          padding: {
            top: 10px;
            bottom: 10px;
            left: 18px;
          }
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 10px;
            height: 10px;
            background: $main-color;
            margin-top: -5px;
            transition: $transition;
          }
          &:last-child {
            border-bottom: 1px solid #eeeeee;
          }
          a {
            color: $paragraph-color;
            display: inline-block;

            &:hover {
              color: $main-color;
            }
          }
          &:hover {
            &::before {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
