
$FontFamily: "Outfit", sans-serif;
$primary-color: #0d1028;
$secondary-color: #ff2d55;
$text-color: #333;
$white: #fff;
$dim-white: #efefef;
$gray: #555;
$black: #000;
$dim-black: #404040;
$heading-color: #112c4c;
$secondary-color: #ff2d55;
$text-color: #333;
$white: #fff;
$dim-white: #efefef;
$gray: #555;
$black: #000;
$dim-black: #404040;
$heading-color: #112c4c;

$font-size: 16px;
$sub-heading: 20px;
$large-heading: 30px;

$font-family: "Poppins", sans-serif;
$main-color: #ff2d55;
$sub-main-color: #00acee;
$black-color: #0d1028;
$white-color: #ffffff;
$yellow-color: #efa506;
$paragraph-color: #707070;
$green-light-color: #8dc63f;
$gradient-color: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, $main-color 80%);
$transition: 0.5s;
$font-size: 15px;

$font-size: 16px;
$sub-heading: 20px;
$large-heading: 30px;
