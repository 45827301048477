/*================================================
Contact Us CSS
=================================================*/
@import "_variables";
.contact-box {
  background: $white-color;
  padding: 30px 20px 30px 90px;
  border-radius: 5px;
  position: relative;
  transition: $transition;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  min-height: 12em;

  .icon {
    position: absolute;
    left: 20px;
    top: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 47px;
    border: 1px dashed $main-color;
    border-radius: 50%;
    color: $main-color;
    font-size: 20px;
    transition: $transition;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .content {
    p {
      margin-bottom: 0;
      word-wrap: break-word;

      a {
        color: $paragraph-color;

        &:hover,
        &:focus {
          color: $main-color;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &:hover,
  &:focus {
    box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
    transform: translateY(-5px);

    .icon {
      background: $main-color;
      color: $white-color;
    }
  }
}
#map {
  height: 400px;
  width: 100%;
}
.contact-form {
  margin-top: 50px !important;
}
.leave-your-message {
  h3 {
    margin-bottom: 12px;
    font-size: 22px;
  }
  .stay-connected {
    margin-top: 25px;

    h3 {
      margin-bottom: 20px;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
        a {
          font-weight: 500;
          display: inline-block;
          color: $paragraph-color;

          i {
            width: 40px;
            height: 40px;
            text-align: center;
            display: inline-block;
            line-height: 40px;
            border: 1px solid;
            border-radius: 50%;
            margin-right: 10px;
            font-size: 15px;
          }
          &:hover,
          &:focus {
            color: $main-color;
          }
        }
      }
    }
  }
}
#contactForm {
  background: $white-color;
  padding: 30px;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);

  label {
    font-weight: 500;
  }
  .form-control {
    background-color: #f1f1f1;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      color: red;
      margin-top: 10px;
      font-size: 14px;
    }
  }
  .text-danger,
  .text-success {
    margin: {
      bottom: 0;
      top: 20px;
    }
  }
  .h4 {
    font-size: 15px;
  }
  .form-group {
    margin-bottom: 30px;
  }
}
