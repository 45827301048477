.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #eeeeee;
}

.containerBody {
  display: flex;
  flex-direction: column;
  width: 90%;
  color: #7b7b7b;
  /* padding-left: 1em; */
  background: #efefef;
  overflow-x: hidden;
  overflow-y: hidden;
  /* box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1); */
}

@media (max-width: 1200px) {
  * {
    box-sizing: border-box;
  }
  .containerBody {
    /* container makes sidebar to cover it*/
    width: 100%;
    position: relative;
    top: left;
    right: left;
  }
}
