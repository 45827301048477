.container {
  border-radius: 5px;
  width: 100%;
  overflow-y: scroll;
  padding-top: 4em;
  padding: 4em 2em;
}

.cardContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  justify-items: center;
  gap: 5em;
  grid-auto-rows: 1fr;
}
