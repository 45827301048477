@import "./../_variables";

.home-event-wrapper {
  padding: 3vh 0;
}
.event-card-right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.home-event-container {
  display: flex;
  width: 100%;
}
.home-event-title {
  font-size: 1.3em;
  font-weight: 500;
  color: $heading-color;
}
.event-arrow {
  color: aliceblue;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: $heading-color;
  display: grid;
  place-items: center;
  position: absolute;
  right: 10px;
  top: 23%;
  opacity: 1;
  transition: all 500ms;
}

.event-arrow:hover {
  cursor: pointer;
  opacity: 0.8;
}

.event-arrow:hover > * {
  transform: translateX(2px);
  transition: all 500ms;
}

.event-heading-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;

  padding: 1em 0 2.2em 0;
  position: relative;
}
.home-event-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 1em;
}
.home-event-card {
  display: flex;
  cursor: pointer;
  // box-shadow: rgba(42, 67, 113, 0.15) 8px 8px 30px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  margin: 0.3em 0;
  padding: 2em;
}
.event-card-left-calender {
  border: 3px solid $primary-color;
  aspect-ratio: 1/1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // padding: 1em;
  min-width: 115px;
  border-radius: 4px;
  margin-right: 1em;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 10px;
    width: 3.2px;
    top: -6px;
    left: 30%;
    border-top: 10px solid $primary-color;
  }
  &::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 3.2px;
    top: -6px;
    left: 70%;
    border-top: 10px solid $primary-color;
  }
}
.event-card-heading {
  font-size: 1.2em;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.event-card-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.event-card-date {
  font-size: 1.6em;
  font-weight: 800;
  margin-bottom: -0.4em;
}

.event-card-months {
  font-size: 1.2em;
  text-transform: capitalize;
}

@media (max-width: 668px) {
  .home-event-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
  }
}
