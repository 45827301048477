.wrapper {
  background-color: rgb(255 255 255);
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  width: 20rem;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease-in-out;
}

.wrapper:hover {
  box-shadow: none;
  background-color: rgb(31 41 55);
}

.wrapper:hover .text {
  color: white;
}

.wrapperAnime {
  transition: all 500 ease-in-out;
}

.header {
  position: relative;
  margin: 0.5rem 0.5rem 0 0.5rem;
  width: 100%;
}

.imageWrapper {
  border-radius: 1rem;
  height: 14rem;
  padding: 0.5em;
  overflow: hidden;
}

.images {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.textWrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 2em 1em 0em 1em;
}

.text {
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: rgb(0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.badgeWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -1rem;
  margin-left: 0.75rem;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.dangerBadge {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background-color: white;
  color: rgb(239 68 68);
  border-radius: 1rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  cursor: pointer;
}

.badgeAnime {
  transform: translateY(0);
  transition: all 300ms ease-in-out;
}

.badgeAnime:hover {
  transform: translateY(-10px);
}

.primaryBadge {
  height: 2.5rem;
  width: 2.5rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: rgb(37 99 235);
  border-radius: 1rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  cursor: pointer;
}

.primaryBadge:hover {
  background-color: rgb(37 99 235);
  color: white;
}

.dangerBadge:hover {
  background-color: rgb(239 68 68);
  color: white;
}

.dateWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 2.5rem;
  margin-bottom: -1rem;
  border-radius: 0.5rem;
  padding: 0.2em 0.5em;
  margin-right: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  background: white;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.dateText {
  color: black;
}
