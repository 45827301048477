.wrapper {
  background-color: rgb(255 255 255);
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  width: 20rem;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease-in-out;
}

.wrapper:hover {
  box-shadow: none;
  background-color: rgb(31 41 55);
}

.wrapper:hover .text {
  color: white;
}
.wrapper:hover .message {
  color: white;
}

.wrapperAnime {
  transition: all 500 ease-in-out;
}

.header {
  position: relative;
  margin: 0.5rem 0.5rem 0 0.5rem;
  width: 100%;
  color: black;
}

.textWrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 2em 1em 0em 1em;
  position: relative;
}

.text {
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: rgb(0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  width: 100%;
  justify-content: center;
}

.badgeWrapper {
  position: absolute;
  bottom: 50%;
  left: 0;
  margin-bottom: -1rem;
  margin-left: 0.75rem;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.badgeAnime {
  transform: translateY(0);
  transition: all 300ms ease-in-out;
}

.badgeAnime:hover {
  transform: translateY(-10px);
}

.primaryBadge {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 1rem;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  cursor: pointer;
}

.unread {
  background-color: rgb(37 99 235);
  color: white;
}

.read {
  background-color: white;
  color: black;
}

.messageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: capitalize;
  padding: 1em 1em 0em 1em;
  gap: 0.25em;
}

.message {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1em;
  /* border-bottom: 1px rgb(189, 182, 182) solid; */
}

.messageKey {
  flex: 50%;
  text-align: left;
  font-weight: 500;
}

.messageValue {
  flex: 50%;
  text-align: left;
}
