/*================================================
Pagination CSS
=================================================*/
@import "_variables";

.pagination-area {
  margin-top: 30px;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      a {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0 5px;
        background-color: $white-color;
        border-radius: 0 !important;
        text-align: center;
        line-height: 40px;
        color: $black-color;
        font: {
          size: 17px;
          weight: 500;
        }
        box-shadow: 0 2px 10px 0 #d8dde6;
        border: none;

        &.active,
        &:hover,
        &:focus {
          background: $main-color;
          color: $white-color;
          box-shadow: unset;
        }
      }
      span {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0 5px;
        background-color: $white-color;
        border-radius: 0 !important;
        text-align: center;
        line-height: 40px;
        color: $black-color;
        font: {
          size: 17px;
          weight: 500;
        }
        box-shadow: 0 2px 10px 0 #d8dde6;
        border: none;

        &.active,
        &:hover,
        &:focus {
          background: $main-color;
          color: $white-color;
          box-shadow: unset;
        }
      }
    }
  }
}
