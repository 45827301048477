.imageContainer {
  width: 20vw;
  height: 20vw;
  align-self: flex-start;
  min-width: 200px;
  min-height: 100px;
  box-shadow: 0 5px 10px #ccc;
  background-color: white;
  border-radius: 6px;
  margin: 2em 2em 2em 2em;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 0;
  align-items: center;
  justify-content: center;
}

.addImageContainer {
  width: 20vw;
  height: 20vw;
  align-self: flex-start;
  min-width: 200px;
  min-height: 100px;
  box-shadow: 0 5px 10px #ccc;
  background-color: white;
  border-radius: 6px;
  margin: 2em 2em 2em 2em;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header {
  background-color: #007fe1;
}

.modalTitle {
  color: #e4e8f1;
}

.closeIcon {
  opacity: 0.8;
  transition: opacity 500ms;
  color: #e4e8f1;
}

.closeIcon:hover {
  opacity: 1;
  cursor: pointer;
}

.modalBody {
  display: flex;
  flex-wrap: wrap;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.modal {
  height: 100%;
  width: 100%;
}

.addPhotoIcon {
  width: 100%;
  height: 100%;
}

.saveButton {
  background-color: #007fe1;
  color: #e4e8f1;
  border: none;
}

.saveButton:hover {
  color: white;
  background-color: #0e7dd1;
}
