.container {
  border-radius: 5px;
  width: 100%;
  overflow-y: scroll;
  padding-top: 4em;
  padding: 4em 2em;
  display: flex;
  flex-direction: column;
}

.imageContainer {
  background-color: #fefefe;
  width: 15vw;
  height: 15vw;
  min-width: 200px;
  min-height: 200px;
  margin-bottom: 2em;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.115rem;
  font-weight: 500;
  position: relative;
}

.overlay {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  opacity: 0;
  position: absolute;
  /* left: 0;
  top: 0;
  width: 0; */
  height: 100%;
  width: 100%;
  background: #a849b54d;
  text-align: center;
  backdrop-filter: blur(4px);
}

.imageContainer:hover .overlay {
  opacity: 1;
  width: 100%;
  animation: fadeIn 750ms;
  cursor: pointer;
}

.changeImage {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  transition: opacity 500ms;
}

.changeImage:hover {
  cursor: pointer;
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.label {
  font-size: 1rem;
  color: #404040;
  font-weight: 700;
}

.imageSection {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: flex-start;
  justify-content: flex-start;
}

.input,
.select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #404040;
  margin-bottom: 2em;
  max-width: 500px;
}

.expiryDate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.buttonContainer {
  display: flex;
}

.error {
  font-size: 0.85rem;
  color: red;
}
