/*btn btn-primary*/
@import "_variables";
.btn {
  font: {
    weight: 600;
    size: $font-size;
  }
  color: $white-color !important;
  box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
  border: none !important;
  padding: 15px 30px;
  border-radius: 0;
  transition: $transition;
}
.btn-primary {
  color: $white-color !important;
  background: $black-color !important;
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 51%;
    background: $main-color;
    height: 100%;
    z-index: -1;
    transition: $transition;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  &:hover::before,
  &:hover::after,
  &:focus::before,
  &:focus::after {
    width: 0;
  }
  &.focus,
  &:focus {
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
    color: $white-color;
  }
  &:hover,
  &:focus {
    background: $black-color;
    color: $white-color;
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background: $black-color;
    color: $white-color;
  }
  &.disabled,
  &:disabled {
    opacity: 1;
    background: $black-color;
  }
}
.btn-secondary {
  color: $white-color;
  background: $black-color;
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 51%;
    background: $sub-main-color;
    height: 100%;
    z-index: -1;
    transition: $transition;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  &:hover::before,
  &:hover::after,
  &:focus::before,
  &:focus::after {
    width: 0;
  }
  &.focus,
  &:focus {
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
    color: $white-color;
  }
  &:hover,
  &:focus {
    background: $black-color;
    color: $white-color;
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background: $black-color;
    color: $white-color;
  }
  &.disabled,
  &:disabled {
    opacity: 1;
    background: $black-color;
  }
}
