/*================================================
Signup CSS
=================================================*/
@import "_variables";
.signup-area {
  height: 100vh;
  position: relative;
  // z-index: 10000;
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.77;
  }
  .signup-form {
    max-width: 540px;
    background: $white-color;
    margin: 0 auto;

    h3 {
      background: $sub-main-color;
      padding: 30px 0 25px;
      font-size: 24px;
      margin-bottom: 0;
      color: $white-color;
      text-align: center;
      text-transform: uppercase;
    }
    form {
      padding: 35px;

      label {
        font: {
          weight: 500;
        }
      }
      .form-control {
        background: transparent;
        font-size: 13px;
      }
    }
    .btn {
      display: block;
      width: 100%;
      margin-top: 20px;
    }
    p {
      text-align: center;
      color: $black-color;
      margin: {
        bottom: 0;
        top: 20px;
      }
      a {
        color: $black-color;
        display: inline-block;
        font: {
          weight: 600;
        }
        &:hover,
        &:focus {
          color: $main-color;
        }
      }
    }
  }
}
