.appHeader {
  background-color: #0d1028;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
}

.wrapperClass {
  height: 40vh;
  width: 100%;
  margin-bottom: 50px;
}
.editorClass {
  background-color: rgb(255, 255, 255);
  padding: 0.5rem;
  border-radius: 0 0 6px 6px;
  overflow-y: scroll;
  border: 1px solid #cccccc;
  color: #404040;
}
.toolbarClass {
  border: none;
  background-color: #0d1028;
  margin: 0;
  border-radius: 6px 6px 0 0;
}
