@import "./../_variables";

.facilities-grid {
  display: grid;
  grid-template-columns: 1fr;
}

.facilities-card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));

  // place-items: center;
  grid-column-gap: 40px;
  grid-row-gap: 80px;
}
.facilities-card {
  justify-items: stretch;
}

@media (max-width: 662px) {
  .facilities-card-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
.facilities-title {
  font-size: 1.6em;
  color: #00acee;
  position: relative;
  margin-bottom: 1em;
  &::before {
    content: "";
    position: absolute;
    width: 10%;
    left: 0;
    bottom: -2px;
    height: 3px;
    border-radius: 2px;
    background-color: #00acee;
  }
}
.facilities-description-content {
  padding: 0.6em;
}

.facilities-description {
  font-size: 1em;
  // height: calc(1.2em * 5);
  line-height: 1.2em;
  // line-clamp: 5;
  // overflow: hidden;
  // text-overflow: ellipsis;
  line-break: strict;
}
.facilities-card-image-container {
  //   height: 150px;
  width: 100%;
}
.facilities-image {
  aspect-ratio: 1/0.5;
  width: inherit;
  object-fit: cover;
}
.facilities-description-content {
  height: fit-content;
}
.facilities-card {
  height: fit-content;
}
