/*================================================
Navbar CSS
*/
@import "_variables";
#navbar {
  background-color: $primary-color;
  position: sticky;
  top: 0;
}

.nav-link {
  padding: 16px 10px;
  color: $white-color !important;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.nav-link:hover {
  color: rgb(225, 31, 38);
}

.navbar-sticky {
  animation: navbar-animation-sticky 1s;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $primary-color;
}

.navbar-absolute {
  animation: navbar-animation-absolute 1s;
  position: absolute;
  width: 100%;
}

@keyframes navbar-animation-sticky {
  from {
    top: -10em;
  }
  to {
    top: 0;
  }
}

@keyframes navbar-animation-absolute {
  from {
    top: -10em;
  }
  to {
    top: 0;
  }
}

.navbar {
  .navbar-nav {
    .nav-link {
      padding: 16px 10px;
      color: $white-color !important;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .nav-link:hover {
      color: rgb(225, 31, 38);
    }
  }
  .nav-item {
    .dropdown-menu {
      margin-top: 0;
      background: $primary-color;
      border: none;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding: 0;
      width: 250px;
      // max-width: 100%;
      .dropdown-item {
        padding: 10px 20px;
        color: $white;
        border-bottom: 1px solid #585858;
      }
      .dropdown-item:hover {
        // background: $secondary-color;
        color: $white;
      }
    }
  }
}
